<template>
  <div 
    class="view-claim-auth" 
    @keyup="onKeyUp">
    <div class="view-claim-auth__body">
      <!-- Header -->
      <header class="view-claim-auth__header">
        <h1 class="view-claim-auth__title">{{ $t('user-portal.user_claim_login_title')}}</h1>
        <p class="view-claim-auth__descr">{{ $t('user-portal.user_claim_login_descr')}}</p>
      </header>

      <!-- Login -->
      <section
        class="view-claim-auth__login flow"
        v-if="!lostPassword">
        <forms-login
          :errors="errors"
          :intent="confirmRoute"
          :key="key"
          @lost-password="onLostPasswordShow"
          v-model="user"
        />

        <actions-button
          appearance="primary"
          class="fill"
          :disabled="isLogging"
          :loading="isLogging"
          @click="onLogin"
        >{{ $t('user-portal.action_confirm') }}</actions-button>
      </section>

      <!-- Lost Password -->
      <section
        class="view-claim-auth__login flow"
        v-if="lostPassword">
        <forms-lost-password
          :errors="errors"
          v-model="userPassword"
        />

        <actions-button
          appearance="primary"
          class="fill"
          :disabled="isLogging"
          :loading="isLogging"
          @click="onLostPasswordClick"
        >{{ $t('user-portal.action_confirm') }}</actions-button>
      </section>

      <!-- Subscribe -->
      <section class="view-claim-auth__alt view-claim-auth__subscribe">
        <div class="view-claim-auth__label">{{ $t('user-portal.user_user_login_subscribe') }}</div>

        <actions-button
          :href="signupRoute"
        >{{ $t('user-portal.action_signup') }}</actions-button>
      </section>
    </div>
  </div>
</template>

<script>
import MixinError from '@/helpers/errors'
import MixinPoapsClaim from '@/modules/claim/helpers/poaps-claim.js'
import MixinRouteLeave from '@/modules/claim/helpers/router-leave.js'

import FormsLogin from '@/modules/user/components/forms/login'
import FormsLostPassword from '@/modules/user/components/forms/lost-password'

export default {
  name: 'ClaimLoginView',

  inject: [
    '$localStorage',
    '$user',

    '$redirect'
  ],

  components: {
    FormsLogin,
    FormsLostPassword
  },

  mixins: [
    MixinError,
    MixinPoapsClaim,
    MixinRouteLeave,
  ],

  data() {
    return {
      errors: {},
      key: 1,
      lostPassword: false,
      userPassword: {},
      isLogging: false
    }
  },

  computed: {
    user() {
      return this.$user.user
    },
  },

  methods: {
    onKeyUp(ev) {
      if(ev.code === 'Enter') {
        this.onLogin()
      }
    },

    onLostPasswordShow() {
      this.userPassword = {
        email: this.user.email || null,
        token: null,
        isTokenShown: false,
        password: null,
        passwordConfirm: null
      }

      this.errors = {}
      this.lostPassword = true
    },

    async onLostPasswordClick() {
      try {
        this.errors = {}
        this.isLogging = true

        if(!this.userPassword.isTokenShown) {
          await this.$user.lostPassword({ email: this.userPassword.email })
          this.userPassword.isTokenShown = true
          return
        }

        await this.$user.changePassword({ 
          email: this.userPassword.email, 
          token: this.userPassword.token, 
          password: this.userPassword.password, 
          passwordConfirm: this.userPassword.passwordConfirm 
        })

        this.$router.replace(this.confirmRoute).catch(() => {})
      } catch(e) {
        this.handleErrors(e, 'lost-password')
      } finally {
        this.key++
        this.isLogging = false
      }
    },

    async onLogin() {
      try {
        this.isLogging = true
        this.errors = {}

        let user = await this.$user.login({ 
          email: this.user.email, 
          password: this.user.password, 
        })

        sayl.injekt.sp.userId = user.id

        this.$router.push(this.confirmRoute).catch(() => {})
      } catch(e) {
        this.handleErrors(e)
      } finally {
        this.isLogging = false
        this.key++
      }
    },
  },

  mounted() {
    this.errors = {}
    this.lostPassword = false
    
    let email = sessionStorage.getItem('email')
    if(email) {
      this.$user.user.email = email
    }
  }
}

</script>